import React, { useState, useEffect } from 'react';
import { Accordion, Card, Button, Form, OverlayTrigger, Spinner} from 'react-bootstrap';
import { FaBriefcase, FaChevronDown, FaChevronRight, FaDatabase, FaRocket  } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import mockData from "./mockData.json"
import 'react-circular-progressbar/dist/styles.css';
import DetailCard from './DetailCard';
import "./index.css"
import empty from "../../../src/assets/images/empty.png"; 
import arrowRight from "../../../src/assets/images/arrow-right.png";
import SettingIcon from "../../components/Icons/JobSettingIcon.svg";
import Wait from "../../../src/assets/images/icons8-two-hands-100.png"
import CompareTarget from './CompareTarget';


const filterSelectedItems = (jobs: Job[]): Job[] => {
  return jobs
    .map(job => {
      if (!job.selected && !job.job_task_groups.some(tg => tg.selected || tg.job_tasks.some(task => task.selected))) {
        return null;
      }

      const filteredTaskGroups = job.job_task_groups
        .map(taskGroup => {

          if (!taskGroup.selected && !taskGroup.job_tasks.some(task => task.selected || task.data_sources.some(ds => ds.selected) || task.custom_plugins.some(cp => cp.selected))) {
            return null;
          }

          const filteredTasks = taskGroup.job_tasks
            .map(task => {

              if (
                !task.selected &&
                !task.data_sources.some(ds => ds.selected) &&
                !task.custom_plugins.some(cp => cp.selected) &&
                !task.target_entities.some(te => te.selected)
              ) {
                return null;
              }

              const filteredDataSources = task.data_sources.filter(ds => ds.selected);

              const filteredCustomPlugins = task.custom_plugins.filter(cp => cp.selected);

              const filteredTargetEntities = task.target_entities.filter(te => te.selected);

              return {
                ...task,
                data_sources: filteredDataSources,
                custom_plugins: filteredCustomPlugins,
                target_entities: filteredTargetEntities,
              };
            })
            .filter(task => task !== null); 

          return {
            ...taskGroup,
            job_tasks: filteredTasks 
          };
        })
        .filter(taskGroup => taskGroup !== null); 
      return {
        ...job,
        job_task_groups: filteredTaskGroups 
      };
    })
    .filter(job => job !== null); 
};

interface DataSource {
  data_source_id: number | null;     
  data_source_name: string | null;   
  source_entity_id: number | null;   
  source_entity_name: string | null; 
  selected: boolean;                 
  source_entity_change_status?: string | null;
}

interface TargetEntities {
  target_entity_id: number | null;   
  entity_name: string | null;        
  selected: boolean;                 
  target_entity_change_status?: string | null;
}

interface JobStep {
  job_step_type_id: number;
  job_step_name: string;
  source_entity_name?: string | null;
  data_source_name?: string | null;
}

interface CustomPlugin {
  custom_plugin_id: number | null;   
  custom_plugin_name: string | null; 
  selected: boolean;                
  custom_plugin_change_status?: string | null;
}

interface JobTask {
  job_task_id: number;
  job_task_name: string;
  // job_task_type: string;
  selected: boolean;
  job_steps: JobStep[]; 
  data_sources: DataSource[];
  custom_plugins: CustomPlugin[];
  target_entities: TargetEntities[];
  jt_change_status?: string | null;
}

interface JobTaskGroup {
  job_task_group_id: number;         
  job_task_group_name: string;       
  layer_id: number;                  
  layer_name: string;                
  selected: boolean;                 
  job_tasks: JobTask[];              
  jtg_change_status?: string | null;
}

interface Job {
  job_id: number;                   
  job_name: string;                  
  selected: boolean;                 
  job_task_groups: JobTaskGroup[];   
  job_change_status?: string | null;
}

const transformDataToNested = (flatData: any[]): Job[] => {
  const jobsMap: { [key: number]: Job } = {};

  flatData.forEach(item => {
    const {
      job_id,
      job_name,
      job_task_group_id,
      job_task_group_name,
      job_task_id,
      job_task_name,
      // job_task_type,
      job_step_name,         
      job_step_type_id,      
      source_entity_name,
      data_source_name,
      data_source_id,        
      source_entity_id,
      target_entity_id,
      entity_name,
      custom_plugin_id,
      custom_plugin_name,
      layer_id,
      layer_name,
      jtg_change_status,
      jt_change_status,
      target_entity_change_status,
      source_entity_change_status,
      custom_plugin_change_status,
      job_change_status
    } = item;

    if (!jobsMap[job_id]) {
      jobsMap[job_id] = {
        job_id,
        job_name,
        selected: false,
        job_task_groups: [],
        job_change_status
      };
    }

    const job = jobsMap[job_id];

    let taskGroup = job.job_task_groups.find(tg => tg.job_task_group_id === job_task_group_id);
    if (!taskGroup) {
      taskGroup = {
        job_task_group_id,
        job_task_group_name,
        layer_id,
        layer_name,
        selected: false,
        job_tasks: [],
        jtg_change_status
      };
      job.job_task_groups.push(taskGroup);
    }

    let task = taskGroup.job_tasks.find(t => t.job_task_name === job_task_name);
    if (!task) {

      task = {
        job_task_id,
        job_task_name,
        // job_task_type,
        jt_change_status,
        selected: false,
        job_steps: [],     
        data_sources: [],
        target_entities: [],
        custom_plugins: [],
      };
      taskGroup.job_tasks.push(task);
    }

    const existingStep = task.job_steps.find(step => step.job_step_type_id === job_step_type_id);
    if (!existingStep) {
      task.job_steps.push({
        job_step_type_id,
        job_step_name,
        source_entity_name,
        data_source_name
      });
    }

    if (source_entity_id && source_entity_name && data_source_id && data_source_name) {
      const existingDataSource = task.data_sources.find(ds => ds.data_source_id === data_source_id);
      if (!existingDataSource) {
        task.data_sources.push({
          data_source_id,
          data_source_name,
          source_entity_id,
          source_entity_name,
          selected: false,
          source_entity_change_status
        });
      }
    }

    if (target_entity_id && entity_name) {
      const existingTargetEntity = task.target_entities.find(te => te.target_entity_id === target_entity_id);
      if (!existingTargetEntity) {
        task.target_entities.push({
          target_entity_id,
          entity_name,
          selected: false,
          target_entity_change_status
        });
      }
    }

    if (custom_plugin_id && custom_plugin_name) {
      const existingPlugin = task.custom_plugins.find(cp => cp.custom_plugin_id === custom_plugin_id);
      if (!existingPlugin) {
        task.custom_plugins.push({
          custom_plugin_id,
          custom_plugin_name,
          selected: false,
          custom_plugin_change_status
        });
      }
    }
  });

  return Object.values(jobsMap);
};



const DroppedJobCard: React.FC<any> = ({ job, packageId, setShowProgress, setJobsToShowProgressFor}) => {

  return(
  <Card style={{
    display: 'flex', 
    flexDirection: 'row', 
    alignItems: 'center', 
    height: '80%',
    width:"80%", 
    borderRadius: '10px',
    padding: '10px',
    backgroundColor: '#f8f9fa',
    boxShadow: '0px 4px 8px rgba(0,0,0,0.1)'
  }}
  onClick={()=>{
    setShowProgress(true)
    setJobsToShowProgressFor(job)
  }}
  >
    <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <FaRocket size={30} color="#1a73e8" />
    </div>
    <div style={{ flex: 6, display: 'flex', flexDirection:"column",justifyContent: 'center', alignItems: 'center' }}>
      Package Id : {packageId}
    </div>
    <div style={{ flex: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Spinner animation="border" role="status" style={{ width: '3rem', height: '3rem', color: '#1a73e8' }}>
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  </Card>
  )
};

const DropZoneCard: React.FC<any> = ({ index, job, handleJobDrop, name, packageId, disabled, setShowProgress, setJobsToShowProgressFor }) => {
  
  return(
  <div
    className={`drop-zone ${disabled ? 'disabled' : ''}`}
    style={{
      border: '2px dashed #ccc',
      height: '250px',
      width: '90%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '10px',
      backgroundColor: '#f8f9fa',
      marginBottom: '10px',
      pointerEvents: disabled ? 'none' : 'auto',
      opacity: disabled ? 0.5 : 1,
    }}
    onDrop={(e) => {
      e.preventDefault();
      const droppedJobs = JSON.parse(e.dataTransfer.getData('application/json'));
      handleJobDrop(index, droppedJobs);
    }}
    onDragOver={(e) => e.preventDefault()}
  >
    {job ? (
      <DroppedJobCard job={job} packageId={packageId} setShowProgress={setShowProgress} setJobsToShowProgressFor={setJobsToShowProgressFor}/>
    ) : (
      <Card style={{ border: 'none', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Card.Body>
          <FaDatabase size={50} color="#1a73e8" style={{ marginBottom: '10px' }} />
          <Card.Title style={{ color: '#1a73e8', fontWeight: 'bold' }}>Deploy Package, {name}</Card.Title>
          <Card.Text style={{ color: '#6c757d', fontSize: '14px' }}>drag and drop a Package for Deployment.</Card.Text>
        </Card.Body>
      </Card>
    )}
  </div>
  )
};


const JobList: React.FC = () => {
  const [jobs, setJobs] = useState<Job[]>([]);
  const [expandedItems, setExpandedItems] = useState<Set<number | string>>(new Set());
  const [selectedHierarchy, setSelectedHierarchy] = useState<Job[]>([]);
  const [filteredItems, setFilteredItems] = useState([])
  const [packageId, setPackageId] = useState("")
  const [droppedJobs, setDroppedJobs] = useState([null, null, null]); 
  const [draggedElement, setDraggedElement] = useState(null); 
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false); 
  const [selectedTargets, setSelectedTargets] = useState("")
  const [packageDeploymentExecution, setPackageDeploymentExecution] = useState<string[] | any>([])
  const [showProgress, setShowProgress] = useState<boolean>(false)
  const [jobToShowProgressFor, setJobsToShowProgressFor] = useState<any>(null)

  const targetEnvironment  = [ "TEST","UAT", "PRODUCTION"]

  useEffect(()=>{
    setPackageId("123")
  },[])

  useEffect(()=>{
    if (selectedTargets !== ""){
      const transformedData = transformDataToNested(mockData);
      setJobs(transformedData);
    }
  },[selectedTargets])

  useEffect(() => {
    const selectedItems = filterSelectedItems(selectedHierarchy);
    setFilteredItems(selectedItems)
  }, [selectedHierarchy]);

  const selectAllDescendants = (subitems: any[], selected: boolean): any[] => {
    return subitems.map(subitem => ({
      ...subitem,
      selected,
      job_task_groups: subitem.job_task_groups
        ? selectAllDescendants(subitem.job_task_groups, selected)
        : [],
      job_tasks: subitem.job_tasks ? selectAllDescendants(subitem.job_tasks, selected) : [],
      data_sources: subitem.data_sources
        ? subitem.data_sources.map((ds: any) => ({ ...ds, selected }))
        : [],
      custom_plugins: subitem.custom_plugins
        ? subitem.custom_plugins.map((cp: any) => ({ ...cp, selected }))
        : [],
    }));
  };

  const updateSelectedHierarchy = () => {
    const selected = jobs.filter(
      job =>
        job.selected ||
        job.job_task_groups.some(
          tg => tg.selected || tg.job_tasks.some(task => task.selected)
        )
    );
    setSelectedHierarchy(selected);
  };

  useEffect(() => {
    updateSelectedHierarchy(); 
  }, [jobs]);

  const handleJobSelect = (jobId: number) => {
    const updatedJobs = jobs.map(job => {
      if (job.job_id === jobId) {
        const newSelectedState = !job.selected;
        return {
          ...job,
          selected: newSelectedState,
          job_task_groups: selectAllDescendants(job.job_task_groups, newSelectedState),
        };
      }
      return job;
    });
    setJobs(updatedJobs);
    setShowProgress(false)
  };

  const handleTaskGroupSelect = (jobId: number, taskGroupId: number) => {
    const updatedJobs = jobs.map(job => {
      if (job.job_id === jobId) {
        const updatedTaskGroups = job.job_task_groups.map(taskGroup => {
          if (taskGroup.job_task_group_id === taskGroupId) {
            const newSelectedState = !taskGroup.selected;
            return {
              ...taskGroup,
              selected: newSelectedState,
              job_tasks: selectAllDescendants(taskGroup.job_tasks, newSelectedState),
            };
          }
          return taskGroup;
        });

        const jobSelected = updatedTaskGroups.some(tg => tg.selected);
        return {
          ...job,
          selected: jobSelected,
          job_task_groups: updatedTaskGroups,
        };
      }
      return job;
    });
    setJobs(updatedJobs);
  };

  const handleJobTaskSelect = (jobId: number, taskGroupId: number, taskId: number) => {
    const updatedJobs = jobs.map(job => {
      if (job.job_id === jobId) {
        const updatedTaskGroups = job.job_task_groups.map(taskGroup => {
          if (taskGroup.job_task_group_id === taskGroupId) {
            const updatedTasks = taskGroup.job_tasks.map(task => {
              if (task.job_task_id === taskId) {
                const newSelectedState = !task.selected;
                return {
                  ...task,
                  selected: newSelectedState,
                  data_sources: task.data_sources.map(ds => ({
                    ...ds,
                    selected: newSelectedState,
                  })),
                  custom_plugins: task.custom_plugins.map(cp => ({
                    ...cp,
                    selected: newSelectedState,
                  })),
                  target_entities: task.target_entities.map(te => ({
                    ...te,
                    selected: newSelectedState,
                  })),
                };
              }
              return task;
            });

            const taskGroupSelected = updatedTasks.some(task => task.selected);

            return {
              ...taskGroup,
              selected: taskGroupSelected,
              job_tasks: updatedTasks,
            };
          }
          return taskGroup;
        });

        const jobSelected = updatedTaskGroups.some(tg => tg.selected);

        return {
          ...job,
          selected: jobSelected,
          job_task_groups: updatedTaskGroups,
        };
      }
      return job;
    });

    setJobs(updatedJobs);
  };

  const renderJobTasks = (jobId: number, taskGroupId: number, tasks: JobTask[]) => (
    <Accordion>
      {tasks.map(task => (
        <div key={task.job_task_id} className="mb-2" style={{ marginLeft: '20px', border: 'none' }}>
          <Card.Header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: 'black', borderRadius: '30px', paddingLeft: '30px', height: "45px" }}>
            <Form.Check
              type="checkbox"
              label={
                <div style={{display:"flex", flexDirection:"row"}}>
                <span className="truncate">
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <div id={`tooltip-${task.job_task_id}`} style={{ padding: "10px", borderRadius: "10px", border: "1px solid black", background: "white" }}>
                        {task.job_task_name} {/* Full name in the tooltip */}
                      </div>
                    }
                  >
                    <span style={{
                      color: "black",
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '90px', // Adjust the width as needed
                      display: 'inline-block',
                      verticalAlign: 'middle',
                      fontWeight:"400"
                    }}>{task.job_task_name}</span>
                  </OverlayTrigger>
                </span>
                {task.jt_change_status !== "No change" && task.jt_change_status !== null && <span style={{
                  color:"green",
                  fontSize:"14px",
                  fontWeight:"500",
                  paddingTop:"5px"
              }}
              >{task.jt_change_status}</span>
              }
              </div>
              }
              checked={task.selected}
              onChange={() => handleJobTaskSelect(jobId, taskGroupId, task.job_task_id)} // Toggle job task selection
            />
            <Button variant="link" onClick={() => togglePanel(task.job_task_id)}>
              {expandedItems.has(task.job_task_id) ? <FaChevronDown/> : <FaChevronRight/>}
            </Button>
          </Card.Header>
          <Accordion.Collapse eventKey={String(task.job_task_id)} in={expandedItems.has(task.job_task_id)}>
            <Card.Body style={{ border: 'none' }}>
              {/* Render job steps */}
              {task.job_steps.map((step:any) => (
                <Form.Check
                  key={step.job_step_type_id}
                  type="checkbox"
                  label={
                    <>
                      <span style={{fontSize:"16px"}}>
                        {`${step.job_step_name}`}{step.data_source_name && `:`}
                        <span style={{
                          color:"green",
                          fontSize:"14px",
                          fontWeight:"500",
                          marginLeft:"50px"
                        }}>{step.source_entity_change_status !== "No change" && step.source_entity_change_status !== null && 
                        step.source_entity_change_status}</span>
                      </span>
                      <br></br>
                      { 
                        <span style={{fontSize:"12px"}}>{step.data_source_name && `${step.source_entity_name}/${step.data_source_name}`}</span>
                      }
                    </>
                    }
                  checked={task.selected} // Reflect job task selection in job step
                  onChange={() => {}} // Disable direct interaction
                  style={{ marginLeft: '70px', pointerEvents: 'none', cursor: 'default' }} // Disable pointer events
                />
              ))}
            </Card.Body>
          </Accordion.Collapse>
        </div>
      ))}
    </Accordion>
  );

  const renderTaskGroups = (jobId: number, taskGroups: JobTaskGroup[]) => (
    <Accordion>
      {taskGroups.map(taskGroup => (
        <div key={taskGroup.job_task_group_id} className="mb-2" style={{ marginLeft: '20px', border: 'none' }}>
          <Card.Header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: 'black', borderRadius: '30px', paddingLeft: '30px' }}>
            <Form.Check
              type="checkbox"
              label={
                <div style={{display:"flex", flexDirection:"row"}}>
                <span
                  className="truncate"
                >
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <div id={`tooltip-${taskGroup.job_task_group_id}`} style={{ background: "white", padding: "10px", borderRadius: "10px", border: "1px solid black" }}>
                        {taskGroup.job_task_group_name} {/* Full name in the tooltip */}
                      </div>
                    }
                  >
                    <span style={{
                      color: "black",
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '90px', // Adjust the width as needed
                      display: 'inline-block',
                      verticalAlign: 'middle',
                      fontWeight:"450"
                    }}>{taskGroup.job_task_group_name}</span>
                  </OverlayTrigger>
                </span>
                  {taskGroup.jtg_change_status !== "No change" && taskGroup.jtg_change_status !== null && <span style={{
                      color:"green",
                      fontSize:"14px",
                      fontWeight:"500",
                      paddingTop:"5px"
                  }}
                  >{taskGroup.jtg_change_status}</span>}
                </div>
              }
              checked={taskGroup.selected}
              onChange={() => handleTaskGroupSelect(jobId, taskGroup.job_task_group_id)}
            />
            <Button variant="link" onClick={() => togglePanel(taskGroup.job_task_group_id)}>
              {expandedItems.has(taskGroup.job_task_group_id) ? <FaChevronDown/> : <FaChevronRight/>}
            </Button>
          </Card.Header>
          <Accordion.Collapse eventKey={String(taskGroup.job_task_group_id)} in={expandedItems.has(taskGroup.job_task_group_id)}>
            <Card.Body style={{ border: 'none' }}>
              {renderJobTasks(jobId, taskGroup.job_task_group_id, taskGroup.job_tasks)}
            </Card.Body>
          </Accordion.Collapse>
        </div>
      ))}
    </Accordion>
  );

  const togglePanel = (id: string | number) => {
    setExpandedItems(prev => {
      const newExpandedItems = new Set(prev);
      if (newExpandedItems.has(id)) {
        newExpandedItems.delete(id);
      } else {
        newExpandedItems.add(id);
      }
      return newExpandedItems;
    });
  };

  const checkDropPosition = (x:any, y:any) => {
    const dropZones = document.querySelectorAll('.drop-zone');
    let isDropped = false;
  
    dropZones.forEach((zone, index) => {
      const rect = zone.getBoundingClientRect();
      if (x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom) {
        isDropped = true;
  
        setDroppedJobs((prevJobs) => {

          if (!prevJobs[index]) {
            const updatedJobs = [...prevJobs];
            updatedJobs[index] = draggedElement;  
            return updatedJobs;
          }
          return prevJobs; 
        });
      }
    });
  
    if (!isDropped) {
      console.log("Dropped outside of valid zones.");
    }
  };

  const handleJobDrop = (index:any, job:any) => {
    setDroppedJobs((prevJobs) => {
      if (!prevJobs[index]) {
        const updatedJobs = [...prevJobs];
        updatedJobs[index] = job; 
        return updatedJobs;
      }
      return prevJobs;  
    });
    setJobs([])
    setPackageDeploymentExecution((prev:any) => {
      if (typeof prev === 'string') {
        return [prev, selectedTargets];
      } else {
        return [...prev, selectedTargets];
      }
    });
    setFilteredItems([])
  };

  const handleJobCompletion = (index:any) => {
    const updatedJobs = [...droppedJobs];
    updatedJobs[index] = null; 
    setDroppedJobs(updatedJobs); 
  };

  useEffect(() => {
    const handleMouseMove = (e:any) => {
      if (isDragging) {
        setPosition({ x: e.clientX, y: e.clientY });
      }
    };
  
    const handleMouseUp = () => {
      checkDropPosition(position.x, position.y);
      setIsDragging(false);
      setDraggedElement(null);
    };
  
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, position, draggedElement]);


  const handleDragStart = (e:any, jobs:any) => {
    e.dataTransfer.setData('application/json', JSON.stringify(jobs)); 

    const dragPreview = document.createElement('div');
    dragPreview.style.width = '150px'; 
    dragPreview.style.height = '50px';
    dragPreview.style.backgroundColor = '#03234a';
    dragPreview.style.color = 'white';
    dragPreview.style.borderRadius = '25px';
    dragPreview.style.padding = '10px';
    dragPreview.style.textAlign = 'center';
    dragPreview.innerText = `Package Id : ${packageId}`; 

    document.body.appendChild(dragPreview);
    e.dataTransfer.setDragImage(dragPreview, 75, 25); 
    e.target.addEventListener('dragend', () => {
      document.body.removeChild(dragPreview);
    }, { once: true });

  };

  const JobListCanvas: React.FC<any> = ({ filteredItems, handleDragStart }) => (
    <div
      draggable="true"
      onDragStart={(e) => handleDragStart(e, filteredItems)} 
      style={{
        border: '2px dashed #ccc',
        height: '90%',
        width: '90%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        borderRadius: '10px',
        backgroundColor: '#f8f9fa',
        marginBottom: '10px',
        marginLeft:"10px",
        flexDirection: 'column',
        overflowY: 'auto',
        padding: '20px',
        cursor: 'grab' 
      }}
    >
      <div style={{display:"flex", flexDirection:"row", gap:"20px", justifyContent:"center", alignItems:"center"}}> <FaBriefcase size={25} color="#1a73e8" /> Deployment Package Id : {packageId}</div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: '20px',
          width: '100%',
          padding: '10px',
        }}
      >
        {filteredItems?.length > 0 ? (
          filteredItems?.map((job: any, index: number) => (
            <Card
              key={index}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '10px',
                backgroundColor: '#fff',
                borderRadius: '10px',
                boxShadow: '0px 4px 8px rgba(0,0,0,0.1)',
              }}
            >
              <img
                src={SettingIcon}
                alt="job icon"
                width={30}
              />
              <span style={{ fontSize: '16px', fontWeight: 'bold', color: '#333' }}>
                {job.job_name}
              </span>
            </Card>
          ))
        ) : (
          <Card
            style={{
              border: 'none',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gridColumn: 'span 2',
            }}
          >
            <Card.Body>
              <FaDatabase size={50} color="#1a73e8" style={{ marginBottom: '10px' }} />
              <Card.Title style={{ color: '#1a73e8', fontWeight: 'bold' }}>No Jobs Available</Card.Title>
              <Card.Text style={{ color: '#6c757d', fontSize: '14px' }}>No jobs in the package.</Card.Text>
            </Card.Body>
          </Card>
        )}
      </div>
    </div>
  );

  return (
    <>
      <div style={{ display: 'flex', height: '85vh', flexDirection:"row", width:"100%" }}>
          <div style={{ width: '25%', height: '100%', padding: '10px',}}>
              <div style={{ 
                    background: "#103057", 
                    color: "white", 
                    fontWeight: "500", 
                    width: "100%", 
                    height: "70px", 
                    marginBottom: "10px", 
                    display: "flex", 
                    justifyContent: "center", 
                    alignItems: "center", 
                    borderRadius: "15px",
                    position: "sticky",  
                    top: 0,              
                    zIndex: 10,           
                  }}>
                  <h3>Deployment - Job</h3>
              </div>
              <div style={{ 
                  background: "white", 
                  color: "black", 
                  fontWeight: "500", 
                  width: "100%", 
                  height: "70px", 
                  marginBottom: "40px", 
                  display: "flex", 
                  justifyContent: "center", 
                  alignItems: "center", 
                  borderRadius: "15px",
                  position: "sticky",  
                  top: 0,              
                  zIndex: 10,     
                  flexDirection:"column",
                  gap:"15px"      
                }}>
                  <div style={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", marginTop:"20px", width:"100%"}}>
                    <span style={{fontSize:"14px"}}>JOBS</span>
                      <img src={arrowRight}/>
                    <span style={{fontSize:"14px"}}>JOB TASK GROUPS</span>
                      <img src={arrowRight}/>
                    <span style={{fontSize:"14px"}}>JOB TASKS</span>
                  </div>
                  {/* <div style={{width:"100%", background:"red", display:"flex"}}> */}
                  <CompareTarget targets={targetEnvironment} setSelectedTargets={setSelectedTargets}/>
                  {/* </div> */}
              </div>
              
              {jobs?.length > 0  ? <Accordion style={{ display: 'flex', flexDirection: 'column', gap: '20px',
                  height: "calc(90% - 70px)",  
                  overflowY: "auto",            
                  paddingTop: "10px",        
                }}>
                  {jobs.map(job => (
                    <div 
                      key={job.job_id} 
                      className="mb-2" 
                      style={{ borderRadius: '30px', color: "black" }} 
                    >
                      <Card.Header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: "black", borderRadius: '30px'}}>
                        <Form.Check
                          type="checkbox"
                          label={
                          <div style={{display:"flex",flexDirection:"row", gap:"20px"}}>
                          <span style={{ 
                            color: 'black', 
                            marginLeft: "20px",
                            borderRadius: '15px', 
                            fontWeight: 'bold',
                            display: 'inline-block'
                          }}>
                            {job.job_name}
                          </span>
                          {job.job_change_status !== "No change" && job.job_change_status !== null && <span style={{
                            color:"green",
                            fontSize:"14px",
                            fontWeight:"500",
                            paddingTop:"5px"
                            }}
                            >{job.job_change_status}</span>}
                        </div>
                          }
                          checked={job.selected}
                          onChange={() => handleJobSelect(job.job_id)}
                          style={{ marginLeft: "30px", padding: "5px" }}
                        />
                        <Button variant="link" onClick={() => togglePanel(job.job_id)} style={{ background: "#e9ecef", borderRadius: "20px" }}>
                          {expandedItems.has(job.job_id) ? <FaChevronDown /> : <FaChevronRight />}
                        </Button>
                      </Card.Header>
                      <Accordion.Collapse eventKey={String(job.job_id)} in={expandedItems.has(job.job_id)}>
                        <Card.Body>{renderTaskGroups(job.job_id, job.job_task_groups)}</Card.Body>
                      </Accordion.Collapse>
                    </div>
                  ))}
                </Accordion>
                :
                <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", height:"60%"}}>
                  <img src={Wait}/>
                  <h1 style={{ fontWeight: 'bold', color: '#b0b0b0', marginTop: '20px' }}>WAIT, LOOK UP !!</h1>
                  <p style={{ color: '#b0b0b0', fontSize: '16px' }}>First You Need To Compare</p>
                  <p style={{ color: '#b0b0b0', fontSize: '16px' }}>Target Environment,</p>
                  <p style={{ color: '#b0b0b0', fontSize: '16px' }}>To get Updated Job List</p>
                </div>
              }
          </div>
          <div style={{ display: 'flex', height: '100%', width:"75%" }}>
          <div style={{ width: '70%', height: '100%', position: 'relative', padding: "10px" }}>
              <div style={{
                background: "#103057", 
                color: "white", 
                fontWeight: "500", 
                width: "100%", 
                height: "70px", 
                marginBottom: "10px", 
                display: "flex", 
                justifyContent: "center", 
                alignItems: "center", 
                borderRadius: "15px",
                position: "sticky",  
                top: 0,              
                zIndex: 10, 
                             
              }}>
                <h3>Deployment - Package</h3>
              </div>
              
              { !(showProgress)?
              <>
                {filteredItems?.length > 0 && 
                  <div style={{ height: 'calc(50% - 70px)', marginBottom: '20px' }}>
                      <div style={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                        <JobListCanvas filteredItems={filteredItems} handleDragStart={handleDragStart}/>
                      </div>
                  </div>
                }

                <div style={{
                  overflowY: 'auto',
                  height: 'calc(60% - 70px)',
                  paddingTop: "10px",
                  zIndex: 1
                }}>
                  {filteredItems?.length > 0 ? (
                    filteredItems?.map((job, index) => (
                      <div key={index}>
                        <DetailCard job={[job]} />
                      </div>
                    ))
                  )
                  : (
                    <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: '40vh', textAlign: 'center' }}>
                      <Card style={{ border: 'none', backgroundColor: 'transparent' }}>
                        <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                          <div style={{ fontSize: '5rem', color: '#b0b0b0' }}>
                            <img src={empty}/>
                          </div>
                          <h1 style={{ fontWeight: 'bold', color: '#b0b0b0', marginTop: '20px' }}>NOTHING!!</h1>
                          <p style={{ color: '#b0b0b0', fontSize: '16px' }}>Your Deployment list is empty.</p>
                        </Card.Body>
                      </Card>
                    </div>
                  )}
                </div>
              </>
              :
              <>
                <div style={{maxHeight:"85%", overflowY:"auto"}}>
                  <DetailCard job={jobToShowProgressFor}/>
                </div>
              </>
              }
            </div>
            <div style={{ width: '50%', padding: '10px', borderRight: '2px solid #ccc', height: '100%', overflowY: 'auto' }}>
              <div style={{ 
                    background: "#103057", 
                    color: "white", 
                    fontWeight: "500", 
                    width: "100%", 
                    height: "70px", 
                    marginBottom: "10px", 
                    display: "flex", 
                    justifyContent: "center", 
                    alignItems: "center", 
                    borderRadius: "15px",
                    position: "sticky",  
                    top: 0,              
                    zIndex: 10           
                  }}>
                  <h3>Deployment - Target</h3>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: "center",
                  alignItems: "center",
                  height: "calc(100% - 70px)",  
                  overflowY: "auto",            
                  paddingTop: "10px"            
                }}
              >
                {targetEnvironment.map((item:string,index:number) => (
                  <DropZoneCard
                    key={index}
                    index={index}
                    job={droppedJobs[index]}
                    name = {item}
                    handleJobDrop={handleJobDrop}
                    handleJobCompletion={handleJobCompletion}
                    packageId = {packageId}
                    disabled = {selectedTargets === item || packageDeploymentExecution.includes(item) ? false : true}
                    setShowProgress={setShowProgress}
                    setJobsToShowProgressFor={setJobsToShowProgressFor}
                  />
                ))}
              </div>
            </div>
            
          </div>
        </div>
        {isDragging && draggedElement && (
        <div
          style={{
            position: 'fixed',
            top: position.y + 10,
            left: position.x + 10,
            pointerEvents: 'none',
            opacity: 1,
            zIndex: 1000,
            background: 'rgba(255, 255, 255, 0.8)', 
            padding: '10px',
            color: '#103057', 
            borderRadius: '15px',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', 
            minWidth: '200px',
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          <span>{draggedElement.job_name}</span>
        </div>
      )}
    </>
  );
};

export default JobList;