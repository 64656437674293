import React, { useState } from 'react';
import { Card, Button, Accordion, Row, Col, ProgressBar } from 'react-bootstrap';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';

interface JobCardProps {
  job: any;
}

const DeploymentProgress: React.FC<{ progressValue: number }> = ({ progressValue }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <Row className="align-items-center" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Col xs={8} className="text-left">
          <span style={{ fontSize: '12px' }}>Deployment package</span>
        </Col>
        <Col xs={2} className="text-right">
          <span>{progressValue}%</span>
        </Col>
      </Row>
      <ProgressBar now={progressValue} variant="success" style={{ height: '8px', marginTop: '5px' }} />
    </div>
  );
};

const DetailCard: React.FC<JobCardProps> = ({ job }) => {
  const [expandedItems, setExpandedItems] = useState<Set<number | string>>(new Set());

  const togglePanel = (id: string | number) => {
    setExpandedItems(prev => {
      const newExpandedItems = new Set(prev);
      if (newExpandedItems.has(id)) {
        newExpandedItems.delete(id);
      } else {
        newExpandedItems.add(id);
      }
      return newExpandedItems;
    });
  };

  return (
    <Accordion style={{ display: 'flex', flexDirection: 'column', gap: '20px', width: '100%' }}>
      {job?.map((singleJob: any) => (
        <Card key={singleJob?.job_id} className="mb-2" style={{ background: 'white', cursor: 'grab' }}>
          {/* Main job card */}
          <Card.Header style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', gap: '20px' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <svg width="10" height="100" viewBox="0 0 10 100" xmlns="http://www.w3.org/2000/svg">
                <circle cx="5" cy="5" r="3" fill="#ccc" />
                <circle cx="5" cy="20" r="3" fill="#ccc" />
                <circle cx="5" cy="35" r="3" fill="#ccc" />
                <circle cx="5" cy="50" r="3" fill="#ccc" />
                <circle cx="5" cy="65" r="3" fill="#ccc" />
                <circle cx="5" cy="80" r="3" fill="#ccc" />
                <circle cx="5" cy="95" r="3" fill="#ccc" />
              </svg>
              <svg width="10" height="100" viewBox="0 0 10 100" xmlns="http://www.w3.org/2000/svg">
                <circle cx="5" cy="5" r="3" fill="#ccc" />
                <circle cx="5" cy="20" r="3" fill="#ccc" />
                <circle cx="5" cy="35" r="3" fill="#ccc" />
                <circle cx="5" cy="50" r="3" fill="#ccc" />
                <circle cx="5" cy="65" r="3" fill="#ccc" />
                <circle cx="5" cy="80" r="3" fill="#ccc" />
                <circle cx="5" cy="95" r="3" fill="#ccc" />
              </svg>
            </div>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <div style={{ width: '95%' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                      <h5 style={{ fontWeight: 'bold' }}>{singleJob.job_name}</h5>
                      <span>{singleJob?.job_task_groups?.map((group: any) => group?.job_task_group_name).join(', ')}</span>
                    </div>
                  </div>
                </div>
                <div>
                  <Button variant="link" onClick={() => togglePanel(singleJob.job_id)} style={{ borderRadius: '20px' }}>
                    {expandedItems?.has(singleJob?.job_id) ? <FaChevronDown /> : <FaChevronRight />}
                  </Button>
                </div>
              </div>
              <div style={{ width: '100%' }}>
                <DeploymentProgress progressValue={50} />
              </div>
            </div>
          </Card.Header>

          {/* Nested accordion for job task groups */}
          <Accordion.Collapse eventKey={String(singleJob?.job_id)} in={expandedItems.has(singleJob?.job_id)} style={{ padding: '15px' }}>
            <>
              {singleJob?.job_task_groups?.map((group: any) => (
                <Accordion key={group?.job_task_group_id}>
                  <Card>
                    <Card.Header style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                      <h6 style={{display:"flex", justifyContent:"center",  alignItems:"center", marginTop:"10px"}}>{group?.job_task_group_name}</h6> {/* Group Name */}
                      <Button variant="link" onClick={() => togglePanel(group?.job_task_group_id)} style={{ borderRadius: '20px' }}>
                        {expandedItems.has(group?.job_task_group_id) ? <FaChevronDown /> : <FaChevronRight />}
                      </Button>
                    </Card.Header>
                    <Accordion.Collapse eventKey={String(group?.job_task_group_id)} in={expandedItems.has(group?.job_task_group_id)} style={{ padding: '15px' }}>
                      <>
                        {group?.job_tasks?.map((task: any) => (
                          <div key={task?.job_task_id} style={{ paddingLeft: '20px', marginBottom: '10px' }}>
                            <h6 style={{ fontWeight: 'bold' }}>{task?.job_task_name}</h6> {/* Task Name */}
                            <DeploymentProgress progressValue={50} />
                            {/* <ul>
                              {task.job_steps.map((step: any) => (
                                <li key={step.job_step_type_id}>
                                  {step.job_step_name}: {step.source_entity_name}/{step.data_source_name}
                                </li>
                              ))}
                            </ul> */}
                          </div>
                        ))}
                      </>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              ))}
            </>
          </Accordion.Collapse>
        </Card>
      ))}
    </Accordion>
  );
};

export default DetailCard;