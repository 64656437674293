import React, { useState } from 'react';
import { Button, Card, Form, Modal } from 'react-bootstrap';
import "./compareTarget.scss";
import { MdTouchApp } from 'react-icons/md';

interface Procs {
  targets : string[];
  setSelectedTargets: any;
}


const CompareTarget: React.FC<Procs> = ({targets, setSelectedTargets}) => {
  const [selectedItem, setSelectedItem] = useState<string | null>("");
  const [showModal, setShowModal] = useState(false);

  const handleSelect = (item:string) => {
    setSelectedItem(item);
  };

  const handleSubmit = () => {
    if (selectedItem.length > 0){
      setSelectedTargets(selectedItem)
    }
    setShowModal(false);
  };

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
    <>
      <Button variant="primary" onClick={handleShow} style={{ display: 'flex', justifyContent: 'center', flexDirection:"row", gap:"20px"}} className='targetButton'>
        <span>Compare Deployment Target</span>
        <MdTouchApp size={20} style={{marginTop:"5px"}}/>
      </Button>

      <Modal 
        show={showModal} 
        onHide={handleClose} 
        centered 
        contentClassName="custom-modal-content"
      >
        <Modal.Header>
            <Modal.Title style={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", width:"100%"}}>
                <span>Select Deployment Target</span>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div style={{maxHeight:"200px",overflowY: 'auto',  display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: '20px',
          width: '100%',
          padding: '10px', }}>
                {targets.map((item:string, index:number) => (
                    <div key={index} style={{ marginBottom: '10px' }} className={item === selectedItem ? 'bounce' : ''}>
                        <Card
                        key={index}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: '10px',
                            backgroundColor: '#fff',
                            borderRadius: '10px',
                            boxShadow: '0px 4px 8px rgba(0,0,0,0.1)',
                        }}
                        >
                            <Form.Check
                                type="radio"
                                id={`item-${item}`}
                                name="dropdown-item"
                                label={item}
                                checked={item === selectedItem}
                                onChange={() => handleSelect(item)}
                                style={{ cursor: 'pointer', fontSize:"20px", fontWeight:"500", display:"flex",flexDirection:"row", justifyContent:"center", alignItems:"center", gap:"30px" }}
                            />
                        </Card>
                    </div>
                ))}
            </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleSubmit} variant="success" disabled={!selectedItem} style={{background:"#03234a"}}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CompareTarget;